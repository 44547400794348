import { Base64 } from 'js-base64'

export function say () {
  console.log('hello World')
}

export function getData () {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, 1500, '')
  })
}

export function sum (a, b) {
  return a + b
}

export function descText (vars, map) {
  if (typeof (map) === 'string') {
    return map
  }
  if (typeof (map) === 'undefined') {
    return ''
  }
  let descText = ''
  for (const i in vars) {
    const v = vars[i]
    const cellVal = map[v.Key]
    if (cellVal) {
      if (typeof (cellVal) === 'object') {
        descText = '{' + this.descText(vars, cellVal) + '}'
      } else {
        descText += '，' + v.Value + '：' + JSON.stringify(cellVal)
      }
    }
  }
  if (descText.length > 0) {
    descText = descText.substring(1)
  }
  return descText
}

export function inWeixinMinProg () {
  const ua = window.navigator.userAgent.toLowerCase()
  const res = ua.match(/MicroMessenger/i) && ua.match(/miniProgram/i)
  return res
}
export function inAlipayMinProg () {
  return navigator.userAgent.indexOf('AlipayClient') > -1
}

export function getCurrMobile () {
  const token = window.localStorage.getItem('accessToken')
  if (!token) {
    return ''
  }
  const jwtbody = Base64.decode(token.split('.')[1])
  return JSON.parse(jwtbody).mob
}
