<template>
  <nav style="max-width: 992px; margin: auto" class="navbar navbar-expand-sm">
    <router-link to="/" class="secondNav">
      <img height="25px;" src="src/img/logo.svg"/>
      <span style="font-size: 21px;">首页</span>
      <img v-if="false" style="position: relative; left: -10px;" height="32" src="src/img/logo-right.png" />
    </router-link>
<!--    <router-link to="/contracts/new" class="secondNav">-->
<!--      <i class="fa fa-plus" aria-hidden="true">创建</i>-->
<!--    </router-link>-->

    <div style="display: inline-flex">
      <el-link type="primary"  style="margin-right: 20px; font-size: 19px; " @click="showManual">
        手册
      </el-link>
      <el-link type="primary" style="margin-right: 20px; font-size: 19px; " @click="popupFeed">
        反馈
      </el-link>
      <router-link to="/login/" class="secondNav">
        管理
      </router-link>
    </div>
    <!-- button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button -->
    <!-- <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav">
        <li class="nav-item">
          <router-link to="/async">async</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/draw">draw</router-link>
        </li>
      </ul>
    </div> -->

  </nav>
</template>
<script>
import { inAlipayMinProg } from '../util.js'

export default {
  data () {
    return {
      comment: '',
      showFeedback: false,
      inAlipayMinProg: inAlipayMinProg()
    }
  },
  methods: {
    popupFeed () {
      this.$emit('showFeedback', this.$route.path)
    },
    showManual () {
      if (this.inAlipayMinProg) {
        window.location.href = 'https://signcheck.online/src/article/manual.html'
      } else {
        window.location.href = 'https://mp.weixin.qq.com/s/80kl7iyqysKGhXoUblcdHg'
      }
    }
  },
  async mounted () {
  }
}

</script>
<style>
.secondNav {
  font-size:  19px;
  color: var(--mainColor);
  display: flex;
  align-items: center;
}
.navbar {
  justify-content:space-between;
  padding: 3px 6px;
}

</style>
